html {
  background-color: black;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter";
  color: lime;
  padding: 0px;
  margin-top: 0px !important;
}

body {
  margin-top: 0px !important;
}

.App {
  top: 0px;
  padding-top: 0px;
  border: 0px;
  margin: 0px;
  padding: 0px;
  text-align: center;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Home {
  border: 0px;
  margin: 0px;
  padding: 0px;
  text-align: center;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}