.modal {
  position: absolute;
  z-index: 10;
  background-color: black;
  border-style: double;
  border-color: whitesmoke;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: 0px 0px 25px whitesmoke;
  border-radius: 25px;
  font-size: 1em;
  align-self: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 24px;
  aspect-ratio: 1/1;
}

.closeButton {
  width: 100%;
  height: 100%;
}

.popup-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal .modalTitle {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  text-align: center;

}

li {
  padding-top: 5px;
  padding-bottom: 5px;
}