.CardList {
  position: absolute;
  top: 110px;
  width: 70%;
}

@media only screen and (max-width: 600px) {
  .CardList {
    top: 85px;
  }
}
