.Header {
  width: 100%;
  top: -21px;
  height: 80px;
  position: fixed;
  z-index: 1;
  background-color: black;
  padding-top: 10px;
}

.Header h1 {
  top: 10px;
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .Header {
    height: 70px;
  }
}


