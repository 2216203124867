.resource-list {
    padding-top: 10px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.listHeader {
    background-color: #454545;
    display: flex;
    flex-direction: row;
    border-bottom: white 2px solid;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 400;
    width: 70%;
}

.type {
    width: calc(10% + 32px);
}
.link {
    width: calc(80% - 102px);
}

.download {
    text-align: right;
    width: calc(10% + 74px);
}

.explanation {
    width: 50%;
    text-align: left;
    margin-bottom: 15px;
}