.title {
  font-size: 19px;
}

.MobileProjectCard {
  padding: 8px;
}

.MobileContainerExpanded {
  border: linen;
  border-style: double;
  border-width: 5px;
  border-radius: 12px;
}

.MobileContainerCollapsed {
  border: linen;
  border-style: double;
  border-width: 5px;
  border-radius: 12px;
  height: 36px;
}

.altDescription {
  text-align: left;
  padding: 8px;
}

.MobileContainerExpanded:hover {
  box-shadow: 0px 0px 13px linen;
}

.MobileContainerCollapsed:hover {
  box-shadow: 0px 0px 13px linen;
}

.MobileContainerExpanded a {
  align-content: center;
  color: lime;
  text-decoration: none;
}

.MobileContainerExpanded a:active {
  color: lime;
  text-decoration: none;
}

.MobileContainerExpanded a:focus {
  color: lime;
  outline: none;
  text-decoration: none;
}

.MobileContainerExpanded a:hover {
  color: lime;
  text-decoration: none;
}
