.TTTSquare {
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  touch-action: none;
  padding: 0;
  margin: 0;
}

.TTTSquare:hover {
  box-shadow: 0px 0px 20px white inset;
}

.TTTSquare img {
  position: relative;
  width: 50%;
}
