.TTTBoard {
  aspect-ratio: 1 / 1;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  touch-action: none;
  bottom: 20px;
}

.GameParent {
  width: 100%;
  height: calc(100% - 190px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  touch-action: none;
  bottom: 0px;
  top: 100px;
}

.SwitchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 40px);
  bottom: 10px;
  position: absolute;
  width: 100%;
  height: 120px;
}
