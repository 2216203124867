.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 788px;
  width: calc(100% - 60px);
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  font-family: 'Segoe UI', Tahoma, sans-serif;
  font-size: 22px;
}

.form button[type="submit"] {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  border-color: #0051FF;
  max-width: 156px;
  width: 20%;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  background-color: #007bff;
  color: #ffffff;

}

.form button[type="submit"]:disabled {
  border-color: #B6B6B4;
  background-color: #848484;
  cursor: not-allowed;
}

.form .desc {
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.form .course {
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.form .location {
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.form .dt {
  flex: 1;
  font-weight: bold;
  margin-right: 10px;
  text-align: left;
}

.form .ct {
  flex: 1;
  text-align: left;
  font-weight: bold;
  margin-right: 10px;
}


.form .lt {
  flex: 1;
  text-align: left;
  font-weight: bold;
  margin-right: 10px;
}

textarea {
  height: 5em;
  resize: none;
}


textarea, input {
  width: 100%;
  border: none;
  font-family: 'Segoe UI', Tahoma, sans-serif;
  font-size: 20px;
}

textarea:focus {
  outline: none; /* Change the border color to blue when focused */
}

input:focus {
  outline: none; 

}

textarea::placeholder {
  font-size: 20px;

}

.fakebutton {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  border-color: #0051FF;
  max-width: 156px;
  width: 20%;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  background-color: #007bff;
  color: #ffffff;
}

.fakebutton:disabled {
  border-color: #B6B6B4;
  background-color: #848484;
  cursor: not-allowed;
}